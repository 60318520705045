.education .education-title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #173b6c;
  }
  
  .education .education-item {
    padding: 0 0 20px 20px;
    margin-top: -2px;
    border-left: 2px solid #1f5297;
    position: relative;
  }
  
  .education .education-item h4 {
    line-height: 18px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #149ddd; 
    margin-bottom: 10px;
  }
  
  .education .education-item h5 {
    font-size: 16px;
    background: #e4edf9;
    padding: 5px 15px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .education .education-item ul {
    padding-left: 20px;
  }
  
  .education .education-item ul li {
    padding-bottom: 10px;
  }
  
  .education .education-item:last-child {
    padding-bottom: 0;
  }
  
  .resume .resume-item::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    left: -9px;
    top: 0;
    background: #fff;
    border: 2px solid #1f5297;
  }