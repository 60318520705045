.experiences .icon-box {
    margin-bottom: 20px;
  }
  
  .experiences .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    background: #149ddd;
    border-radius: 50%;
    transition: 0.5s;
    border: 1px solid #149ddd;
  }
  
  .experiences .icon i {
    color: #fff;
    font-size: 24px;
    line-height: 0;
  }
  
  .experiences .icon-box:hover .icon {
    background: #fff;
  }
  
  .experiences .icon-box:hover .icon i {
    color: #149ddd;
  }