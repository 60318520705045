.experiences .title {
    margin-left: 80px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .experiences .title a {
    color: #343a40;
  }
  
  .experiences .title a:hover {
    color: #149ddd;
  }
  
  .experiences .description {
    margin-left: 80px;
    line-height: 24px;
    font-size: 14px;
  }