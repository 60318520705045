  .home {
    width: 100%;
    height: 100vh;
    background: url("../../assets/img/home-background.jpg") top center;
    background-repeat:no-repeat;
    background-position:center;
    background-size: cover;
  }
  
.home:before {
    background: rgba(5, 13, 24, 0.3);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

.home .landing {
  position: absolute;
  top: 50%;
  left: 50%;
}

.hero-container {
  position: relative;
  z-index: 2;
  min-width: 300px;
}

.home h1 {
  margin: 0 0 10px 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

.home p {
  color: #fff;
  margin-bottom: 50px;
  font-size: 26px;
  font-family: "Poppins", sans-serif;
}

.home p span {
  color: #fff;
  padding-bottom: 4px;
  letter-spacing: 1px;
  border-bottom: 3px solid #149ddd;
}
